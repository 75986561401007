import { Mail, Phone, GitHub, Linkedin} from 'react-feather';

import fredrikAvatar from './images/FredrikVullum.jpg'; 

export default function HomePage() {
    return (
        <div className="min-h-screen flex items-center justify-center flex-col">
            <section id="about" className="w-full max-w-3xl mt-8 mb-8">
                <div className="bg-zinc-950/[.50] p-8 text-white rounded-lg text-center ">
                    <h1 className="text-3xl font-bold mb-4">About</h1>
                    <img src={fredrikAvatar} alt="Fredrik Vullum" className="mx-auto mb-4 w-40 h-48 rounded-md w-auto max-h-full object-cover border-4 border-blue-300/75 " />
                    <p className="text-left">
                    My name is Fredrik Vullum. I'm a software developer based in Oslo, Norway. With a Bachelor's degree in Computer Science from The University of Tromsø, I have a strong foundation in software development, algorithms, data structures, and more. Currently, I'm working as a consultant at <a rel="noopener noreferrer"className="text-blue-300 hover:underline font-bold" href="https://www.evidi.com" target="_blank">Evidi</a>, where I specialize in integrating IT systems using Azure and .NET.
                    </p>
                </div>
            </section>
            <section id="projects" className="w-full max-w-3xl mb-8">
            <div className="bg-zinc-950/[.50] p-8 text-white rounded-lg text-center">
                    <h1 className="text-3xl font-bold mb-4">Projects</h1>
                    <a href="https://www.gk.no" target="_blank" rel="noopener noreferrer" className="text-lg font-bold text-blue-300 hover:underline block mt-4 mb-4 text-left">Gk Norge AS</a>
                    <p className="text-left">As an outsourced consultant at GK Norge AS, my main responsibility is to enable their IT systems to communicate through integrations.</p>
                    <a href="https://www.rema.no" target="_blank" rel="noopener noreferrer" className="text-lg font-bold text-blue-300 hover:underline block mt-4 mb-4 text-left">Rema 1000 AS</a>
                    <p className="text-left">As an outsourced consultant at Rema 1000 AS, I assisted in upgrading and expanding their internal NuGet packages used by their electronic grocery system.</p>
                    <a href="https://www.evidi.com" target="_blank" rel="noopener noreferrer" className="text-lg font-bold text-blue-300 hover:underline block mt-4 mb-4 text-left">Evidi AS</a>
                    <p className="text-left">Internally at Evidi AS, I have worked with developing a portal for our consultants and a back-end solution for registering hours. </p>
                </div>
            </section>
            <section id="skills" className="w-full max-w-3xl mb-8">
                <div className="bg-zinc-950/[.50] p-8 text-white rounded-lg text-center">
                    <h1 className="text-3xl font-bold mb-4">Skills</h1>
                    <p className="text-lg font-bold">Technologies</p>
                    <p>C# | OOP | .NET | ASP.NET Core | CI/CD | SQL | MVC | TDD | REST | Git</p>
                    <p>OAuth2 | MySQL | DevOps | Scrum | Confluence | JSON | XML | CSV | GitHub</p>
                    <p>JWT | Azure Function Apps | Azure DevOps | Azure Logic Apps | Azure Service Bus</p>
                    <p>Azure Storage | Azure Pipelines | Azure Key Vault | Azure Cosmos DB | Azure API Management </p>
                    <p className="mt-2 text-lg font-bold">Certifications</p>
                    <p>AZ-900</p>
                </div>
            </section>
            <section id="contact" className="w-full max-w-3xl mb-8">
                <div className="bg-zinc-950/[.50] p-8 text-white rounded-lg text-center">
                    <h1 className="text-3xl font-bold mb-4">Contact</h1>
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex items-center justify-center mb-4">
                            <Phone className="mr-2" />
                            <p>+4747340103</p>
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <Mail className="mr-2" />
                            <a href="vullum.fredrik@gmail.com" className="text-blue-300 hover:underline">vullum.fredrik@gmail.com</a>
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <Linkedin className="mr-2" />
                            <a href="https://www.linkedin.com/in/fredrik-vullum/en/" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:underline block">fredrik-vullum</a>
                        </div>
                        <div className="flex items-center justify-center">
                            <GitHub className="mr-2" />
                            <a href="https://github.com/FredrikVullum/" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:underline block">FredrikVullum</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
